a {
    color: var(--info);
    text-decoration: none;
}

a:hover,
a:focus {
    color: var(--cyan);
}

.page-center {
    -ms-flex-align: center;
    align-items: center;
    background-color: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
}

/*avatar-wrapper*/
.avatar-wrapper {
    position: relative;
    height: 200px;
    width: 200px;
    margin: 50px auto;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 1px 15px -5px black;
    transition: all 0.3s ease;
}

.avatar-wrapper:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.avatar-wrapper:hover .profile-pic {
    opacity: 0.5;
}

.avatar-wrapper .profile-pic {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
}

.avatar-wrapper .profile-pic:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 190px;
    background: #ecf0f1;
    color: #34495e;
    text-align: center;
}

.avatar-wrapper .upload-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.avatar-wrapper .upload-button i {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 44px;
    line-height: 200px;
    background-color: #EDEBE1;
    color: #fff;
}

.avatar-wrapper .upload-button:hover i {
    opacity: 0.9;
}

.card-header>.card-tools .input-group,
.card-header>.card-tools .nav,
.card-header>.card-tools .pagination {
    margin-bottom: 0;
    margin-top: 0;
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 400;
}

.h5,
h5 {
    font-weight: 400;
}

.navbar-light .navbar-nav .nav-link {
    color: #664e04;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: var(--info);
}

.form-label,
.form-check {
    color: var(--info);
}

.form-check-input:checked {
    background-color: var(--info);
    border-color: var(--info);
}

.page-item.active .page-link {
    background-color: var(--info);
    border-color: var(--info);
}

.page-link {
    color: var(--info);
}

.nav-tabs .nav-link {
    color: var(--info);
}

.nav-tabs .nav-link:hover {
    color: var(--info);
}

.form-check-inline .form-check-input {
    margin-top: .25em;
}

.accordion-button:not(.collapsed) {
    color: var(--info);
    background-color: var(--bs-accordion-bg);
}

.table-noborder th {
    border-color: transparent;
}

.table-noborder thead th {
    color: var(--info);
    border-color: transparent;
    font-weight: 400;
}

.table-noborder tr {
    border-color: transparent;
}

:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.nav-sidebar>.nav-header hr {
    margin: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--info);
}

.nav-pills .nav-link:not(.active):hover {
    color: var(--info);
}

tr.level-1 {
    font-weight: 600;
}

tr.level-2 {
    font-weight: 600;
    font-style: italic;
}

tr.level-2 td:first-child {
    padding-left: 30px;
}

tr.level-3 td:first-child {
    padding-left: 60px;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-width: 600px;
}

.calendarWrap {
    display: inline-block;
    position: relative;
}

.calendarWrap input:read-only {
    background-color: #fff;
    width: max-content;
}

.calendarWrap .calendarElement {
    position: absolute;
    left: 0;
    border: solid 1px #ccc;
    z-index: 9999;
}

canvas.chartjs-box {
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
}